import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import qs from "qs"
import Prismic from "prismic-javascript"

import { SEO, Flex, Heading } from "components"

import Home from "../index"
import About from "../about"
import Contact from "../contact"
import Project from "../../templates/project"

const linkResolver = function(doc) {
  return doc
}

function Preview(props) {
  const [loading, setLoading] = useState(true)
  const [preview, setPreview] = useState({})
  const { categories } = useStaticQuery(
    graphql`
      {
        categories: allPrismicCategory {
          nodes {
            prismicId
            data {
              name {
                text
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    loadPreview()
  }, [])

  const loadPreview = () => {
    const params = qs.parse(props.location.search.slice(1))
    Prismic.getApi("https://park.cdn.prismic.io/api/v2").then(api => {
      api.previewSession(params.token, linkResolver, "/").then(document => {
        if (document.type === "project") {
          categories.nodes.forEach(category => {
            if (
              document.data.category &&
              category.prismicId === document.data.category.id
            ) {
              document.data.category.name = [{ text: category.data.name.text }]
            }
          })
        } else {
          if (document.type === "about" || document.type === "contact") {
            props.setTheme("dark")
          }
        }
        setPreview(document)
        setLoading(false)
      })
    })
  }

  return (
    <>
      <SEO title="Preview">
        <script type="text/javascript">
          {`window.prismic = 
                    { endpoint: 'https://` +
            process.env.GATSBY_PRISMIC_REPO +
            `.prismic.io/api/v2'
                    };`}
        </script>
        <script
          type="text/javascript"
          src="//static.cdn.prismic.io/prismic.min.js"
        />
        <meta name="robots" content="noindex" />
      </SEO>
      {loading && (
        <Flex height="100vh" justifyContent="center" alignItems="center">
          <Heading size={900} as="h1">
            Loading preview
          </Heading>
        </Flex>
      )}
      {!loading && (
        <>
          {preview.type === "about" && <About preview={preview} {...props} />}
          {preview.type === "project" && (
            <Project preview={preview} {...props} />
          )}
          {preview.type === "contact" && (
            <Contact preview={preview} {...props} />
          )}
          {(preview.type === "category" || preview.type === "home_page") && (
            <Home preview={preview} {...props} />
          )}
        </>
      )}
    </>
  )
}

export default Preview
