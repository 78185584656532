import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import css from "@styled-system/css"

import {
  Box,
  FluidText,
  Footer,
  PageHeader,
  Paragraph,
  SEO,
  VStack,
  Wrapper,
} from "components"

import { HeaderFade } from "animations"

function ContactPage({ preview }) {
  const { page } = useStaticQuery(
    graphql`
      {
        page: prismicContact {
          dataString
        }
      }
    `
  )

  const [contactPage, setContactPage] = useState(() => {
    if (page.dataString) {
      let dataParsed = JSON.parse(page.dataString)
      return {
        seo: {
          title: dataParsed.seo_title && dataParsed.seo_title[0].text,
          description:
            dataParsed.seo_description && dataParsed.seo_description[0].text,
          image: dataParsed.seo_image && dataParsed.seo_image.url,
          imageAlt: dataParsed.seo_image && dataParsed.seo_image.alt,
        },
        title: dataParsed.title && dataParsed.title[0].text,
        socialNetworks: dataParsed.social_networks,
        contacts: dataParsed.contacts,
      }
    }
  })

  useEffect(() => {
    if (page.dataString) {
      if (preview) {
        setContactPage({
          seo: {
            title: preview.data.seo_title && preview.data.seo_title[0].text,
            description:
              preview.data.seo_description &&
              preview.data.seo_description[0].text,
            image: preview.data.seo_image && preview.data.seo_image.url,
            imageAlt: preview.data.seo_image && preview.data.seo_image.alt,
          },
          title: preview.data.title && preview.data.title[0].text,
          socialNetworks: preview.data.social_networks,
          contacts: preview.data.contacts,
        })
      }
    }
  }, [])

  return (
    <>
      {contactPage && contactPage.seo && (
        <SEO
          title={contactPage.seo && contactPage.seo.title}
          description={contactPage.seo && contactPage.seo.description}
          image={contactPage.seo && contactPage.seo.image}
          imageAlt={contactPage.seo && contactPage.seo.imageAlt}
        />
      )}

      <PageHeader />

      {/* ================== HERO ================== */}
      <Wrapper pt={["layout.6", "layout.7"]}>
        {/* Heading */}
        <motion.div
          variants={HeaderFade(0.33)}
          initial="initial"
          animate="visible"
          exit="exit"
          key={"contactHeroText"}
        >
          <FluidText size={900}>{contactPage.title}</FluidText>
        </motion.div>
      </Wrapper>

      <Box minHeight="67vh">
        <motion.div
          variants={HeaderFade(0.7)}
          initial="initial"
          animate="visible"
          exit="exit"
          key={"contactBody"}
        >
          <Wrapper py={["layout.6", "layout.7"]}>
            <VStack space={["layout.2", "layout.4"]}>
              {contactPage.socialNetworks &&
                contactPage.socialNetworks.map(
                  ({ label, handle, link }, index) => (
                    <Paragraph
                      as="p"
                      size={600}
                      css={css({
                        display: "flex",
                        flexWrap: "wrap",
                      })}
                      key={"socialNetwork" + index}
                    >
                      <Box
                        as="span"
                        m={0}
                        mr="layout.1"
                        fontFamily="sans"
                        textAlign="left"
                      >
                        {label}
                      </Box>
                      <Box as="span" textAlign="left">
                        <Box
                          as={link && link.url ? "a" : "span"}
                          href={link && link.url}
                          target="_blank"
                          rel="nopener noreferrer"
                        >
                          {handle}
                        </Box>
                      </Box>
                    </Paragraph>
                  )
                )}
              {contactPage.contacts &&
                contactPage.contacts.map(({ name, phone, email }, index) => (
                  <Paragraph
                    as="p"
                    size={600}
                    css={css({
                      display: "flex",
                      flexWrap: "wrap",
                    })}
                    key={"contact" + index}
                  >
                    <Box
                      as="span"
                      m={0}
                      mr="layout.1"
                      fontFamily="sans"
                      textAlign="left"
                    >
                      {name}
                    </Box>
                    <Box as="span" textAlign="left">
                      {email && (
                        <Box
                          as="a"
                          href={"mailto:" + email}
                          target="_blank"
                          rel="nopener noreferrer"
                          display="block"
                        >
                          {email}
                        </Box>
                      )}
                      {phone && (
                        <Box
                          as="a"
                          href={"tel:" + phone}
                          target="_blank"
                          rel="nopener noreferrer"
                          display="block"
                        >
                          {phone}
                        </Box>
                      )}
                    </Box>
                  </Paragraph>
                ))}
            </VStack>
          </Wrapper>
        </motion.div>
      </Box>

      <Footer />
    </>
  )
}

export default ContactPage
